import { ContactPageSettingsI } from '../../types'
import commonClientPageSettings from './common'

const contactPageSettings: ContactPageSettingsI = {
  ...commonClientPageSettings,
  form: {
    enabled: false,
    isSurvey: false,
    displayToastNotification: false,
    toastNotificationPersistentDismiss: true,
    // For permanent dismissal toast notification needs to have a unique ID
    // surveyId: 'insights-survey-nov-24',
    // href: 'https://forms.office.com/r/grtmvyRmKr',
    image: '/images/form.png',
  },
}

export default contactPageSettings
