import { sortBy, startCase, sumBy } from 'lodash'

import { MetricSettingsI } from '@/config/types'

import getFormOption from '../../../utils/getFormOption'

const fallback: any[] = []

const sortByLargestToSmallest = (split: any) => {
  return sortBy(split, (_split) => {
    const data: [] = _split?.data || []

    if (data.length === 0) return 0

    if (_split.slug === 'total') return -Infinity

    const total = sumBy(data, (datum) => datum[1])

    return -total
  })
}

const convivaTimeseriesToLine = ({
  settings,
  config,
  data,
  chartColors,
}: {
  settings: MetricSettingsI
  config: ConfigI
  data?: ConvivaTimeseriesResponseI
  chartColors?: string[]
}) => {
  const { dataKey } = settings

  if (data?.data) {
    //Hack to fix fact API always returns total object
    if (
      Object.keys(data?.data).length === 1 &&
      data?.data?.total?.length === 0
    ) {
      return fallback
    }

    const lineData = Object.keys(data?.data).map((splitName) => {
      const { label, image, icon, color } = getFormOption({
        value: splitName,
        formGroup: config['split-by'],
        settings,
        chartColors,
      })

      return {
        name: label || startCase(splitName),
        image,
        yAxis: 0,
        icon,
        color,
        slug: splitName,
        id: `${splitName}-${dataKey}`,

        data:
          data?.data[splitName].map((datum: any) => {
            return [datum.timestamp, datum[dataKey]]
          }) || [],
      }
    })

    const lineDataSorted = sortByLargestToSmallest(lineData)

    return lineDataSorted
  } else {
    return fallback
  }
}

export default convivaTimeseriesToLine
