import { ContactPageSettingsI } from '../../types'

const contactPageSettings: ContactPageSettingsI = {
  slack: {
    teamId: {
      nbcu: 'E22CSPSSD',
      sky: 'E03D4T4JK63',
    },
    enabled: true,
    channel: '#gst-insights-support',
    id: 'C074TEQ53PT',
    image: '/images/slack.png',
  },
  email: {
    enabled: true,
    address: 'gstops.qualityanalytics@nbcuni.com',
    image: '/images/email.png',
  },
  form: {
    enabled: false,
    // href: 'https://forms.office.com/e/H5KNt6Y8rj', // old microfost form link
    image: '/images/form.png',
  },
}

export default contactPageSettings
