import React from 'react'
import { HitsProvided, StateResultsProvided } from 'react-instantsearch-core'
import { connectHits, connectStateResults } from 'react-instantsearch-dom'

import Status from '@/components/Status'

const LOADING_DELAY = 1000

interface HitsStatusI extends StateResultsProvided {
  isEmpty?: boolean
  children: React.ReactElement
}

const HitsStatus = ({
  error,
  searching,
  isEmpty = false,
  children,
}: HitsStatusI) => {
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    // Only show loader after 1 second to avoid having a loader
    // flashing up and rerendering every key stroke
    let timeoutId: NodeJS.Timeout
    if (searching) {
      timeoutId = setTimeout(() => {
        setLoading(true)
      }, LOADING_DELAY)
    } else {
      setLoading(false)
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [searching])

  // if (isEmpty && searching) return null

  return (
    <Status
      isEmpty={isEmpty && !searching}
      error={error}
      status={loading ? 'loading' : error ? 'error' : 'success'}
      isEmptyMessage='No events could be found'
      isEmptySubMessage='Try using a different search term'
      statusWrapperProps={{ className: 'mt-24' }}
    >
      {children}
    </Status>
  )
}

const ConnectedHitsStatus = connectStateResults<HitsStatusI>(HitsStatus)

interface HitsWithStatusI extends HitsProvided<any> {
  hitComponent: React.ComponentType<any>
  hitsClassName?: string
  children: any
}

const HitsWithStatus = ({
  hits,
  hitComponent: HitComponent,
  hitsClassName,
}: HitsWithStatusI) => {
  const isEmpty = hits.length === 0
  return (
    <ConnectedHitsStatus isEmpty={isEmpty}>
      <ol className={isEmpty ? '' : hitsClassName}>
        {hits.map((hit: any) => (
          <HitComponent key={hit.id} hit={hit} />
        ))}
      </ol>
    </ConnectedHitsStatus>
  )
}

const CustomHits = connectHits<HitsWithStatusI>(HitsWithStatus)
export default CustomHits
