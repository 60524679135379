import {
  logEvent as firebaseLogEvent,
  setUserId as firebaseSetUserId,
  setUserProperties as firebaseSetUserProperties,
} from 'firebase/analytics'
import camelCase from 'lodash/camelCase'

import appSettings from '@/config/appSettings'
import { analyticsInstance } from '@/index'

type UserProperties = {
  [key: string]: number | string | null
}

// Firebase doesn't allow custom definitions to be set up with hyphenation
// Standardise everything to camelCase
function convertKeysToCamelCase(obj: Record<string, any>): Record<string, any> {
  const safeList = ['page_path'] // List of keys to exclude from camelCase conversion
  const camelCasedObj: Record<string, any> = {}

  Object.keys(obj).forEach((key) => {
    if (safeList.includes(key)) {
      camelCasedObj[key] = obj[key]
    } else {
      camelCasedObj[camelCase(key)] = obj[key]
    }
  })

  return camelCasedObj
}

const analyticsEnabled = appSettings?.analytics?.enabled

export const logEvent = (eventName: string, eventParams?: object): void => {
  if (analyticsEnabled && analyticsInstance) {
    const camelCasedParams = eventParams
      ? convertKeysToCamelCase(eventParams)
      : {}

    firebaseLogEvent(analyticsInstance, eventName, camelCasedParams)
  }
}

export const setUserProperties = (properties: UserProperties): void => {
  if (analyticsEnabled && analyticsInstance) {
    firebaseSetUserProperties(analyticsInstance, properties)
  }
}

export const setCurrentScreen = (currentScreen: string): void => {
  if (analyticsEnabled && analyticsInstance) {
    firebaseLogEvent(analyticsInstance, 'screen-view', {
      screen_name: currentScreen,
    })
  }
}

export const setUserId = (userId: string): void => {
  if (analyticsEnabled && analyticsInstance) {
    firebaseSetUserId(analyticsInstance, userId)
  }
}
