import { startCase } from 'lodash'

import { formOptions } from '@/config/formOptions'
import {
  DEPARTMENT,
  DEVICE,
  DEVICE_TYPE,
  EVENT_REPORT_RANK,
  INCIDENT_SEVERITY,
  OPERATING_SYSTEM,
  PACKAGE,
  PRODUCT,
  PROPOSITION,
  STATUS_GROUP,
  STREAM_TYPE,
  TERRITORY,
} from '@/config/formOptions'
import {
  FormOptionConfigI,
  FormOptionT,
  MetricSettingsI,
  ReportSettingsI,
  SettingsI,
} from '@/config/types'

export const mapFormGroupToConstants: { [key: string]: FormOptionT } = {
  territory: TERRITORY,
  proposition: PROPOSITION,
  'stream-type': STREAM_TYPE,
  device: DEVICE,
  'device-type': DEVICE_TYPE,
  'operating-system': OPERATING_SYSTEM,
  package: PACKAGE,
  product: PRODUCT,
  rank: EVENT_REPORT_RANK,
  severity: INCIDENT_SEVERITY,
  department: DEPARTMENT,
  'status-group': STATUS_GROUP,
}

const getFormOption = ({
  value,
  formGroup,
  settings,
  chartColors,
}: {
  value?: string
  formGroup?: string | null
  settings?: SettingsI | MetricSettingsI | ReportSettingsI
  chartColors?: string[]
}): FormOptionConfigI => {
  if (formGroup) {
    const formSettings = settings?.form?.find(
      ({ value }) => value === formGroup,
    )

    if (formSettings) {
      if (formSettings?.options?.length > 0) {
        const formOptionIndex = formSettings?.options?.findIndex((option) => {
          return (
            option[0] === value || (value === 'total' && option[0] === 'all')
          )
        })
        if (formOptionIndex >= 0) {
          const formOption = formSettings?.options[formOptionIndex]

          if (formOption && formOption[1]) {
            return {
              label: startCase(value),
              color: chartColors?.[formOptionIndex],
              ...formOption[1],
            }
          }
        }
      }
    } else {
      if (formGroup && value && formOptions[formGroup]) {
        let accessor = value
        if (value === 'total' || value === 'aggregate') {
          accessor = 'all'
        }
        return {
          label:
            formOptions[formGroup][accessor]?.[1]?.label ?? startCase(value),
          ...formOptions[formGroup][accessor]?.[1],
        }
      }
    }
  }
  return {}
}

export default getFormOption
