const certificatesPageSettings = {
  label: 'Global Certificates Overview',
  categoryLabel: {
    org: 'Organization',
    team: 'Team Name',
    vp: 'VP',
  },
  info: {
    description:
      'We are ingesting data from all NBCU teams through Cloud Resource Scanner and partial data from Sky teams through Mimir. The teams/data that still need to be onboarded to Mimir are:',
    teamsStatus: [
      { name: 'Atom', status: 'ETA end of December.' },
      {
        name: 'Atom (Legacy)',
        status: 'No ETA yet, our teams are working together on a solution.',
      },
      {
        name: 'BiBCD',
        status: `ETA end of December (already onboarded, but data format needs to be updated).`,
      },
      { name: 'Edge', status: 'SST (only NBCU US is being sent now).' },
    ],
  },
}

export default Object.freeze(certificatesPageSettings)
