import moment from 'moment-timezone'
import { CgSpinner } from 'react-icons/cg'

import { useTimeStore } from '@/stores/time.store'
import { cn } from '@/utils/cn'
import getTimezoneLabel from '@/utils/getTimezoneLabel'

const LastUpdatedTime = ({
  isRefreshing,
  timestamp,
  containerClasses,
  isUTC,
  displayDate,
}: {
  isRefreshing?: boolean
  timestamp?: number | null
  containerClasses?: string
  isUTC?: boolean
  displayDate?: boolean
}) => {
  const storeTimezone = useTimeStore((state) => state.timezone)
  const timezone = isUTC ? 'UTC' : storeTimezone

  if (!timestamp) return null

  const timestampFormat = displayDate ? 'DD MMM YYYY HH:mm' : 'HH:mm'

  return (
    <div
      className={cn(
        'text-[10px] text-text-tertiary flex items-center space-x-1',
        containerClasses,
      )}
    >
      {isRefreshing && <CgSpinner className={cn('w-3 h-3 animate-spin')} />}
      <p className='text-[10px]'>Last updated</p>
      <span>{moment(timestamp).tz(timezone).format(timestampFormat)}</span>
      <span>{getTimezoneLabel(timestamp, timezone)}</span>
    </div>
  )
}

export default LastUpdatedTime
