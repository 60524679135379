import { sumBy } from 'lodash'

import { MetricSettingsI } from '@/config/types'

const fallback = 0

const convivaTimeseriesTotal = ({
  settings,
  data,
}: {
  settings: MetricSettingsI
  data: ConvivaTimeseriesResponseI
}) => {
  const { dataKey, aggregationType } = settings

  if (data?.data && aggregationType === 'total') {
    // Hack to fix fact API always returns total object
    if (
      Object.keys(data?.data)?.length === 1 &&
      data?.data?.total?.length === 0
    )
      return fallback
    if (data?.data?.total) {
      const total = sumBy(data.data.total, dataKey) || fallback
      return total
    } else {
      return fallback
    }
  } else {
    return fallback
  }
}

export default convivaTimeseriesTotal
