import { useQuery } from 'react-query'

import useSettings from '@/hooks/useSettings'
import { useUserStore } from '@/stores/user.store'
import { createUserProperties } from '@/utils/createUserProperties'
import { setUserId, setUserProperties } from '@/utils/firebaseAnalytics'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react'

import { loginRequest } from '../authConfig'
import PageLoading from '../components/PageLoading'
import Login from '../pages/Login'
import { getUser } from '../utils/getUser'

const Authentication = ({ children }: { children: any }) => {
  const { appSettings } = useSettings()
  const setUser = useUserStore((state) => state.setUser)
  const { instance: msalInstance, inProgress } = useMsal()

  useQuery<ADUserI>(['user', inProgress], getUser, {
    onSuccess: (userData) => {
      if (!userData) {
        console.log('User data is null or undefined.')
        return // Exit early if userData is invalid
      }
      // set user to store
      setUser(userData)
      // log user
      if (appSettings?.analytics?.enabled) {
        setUserId(userData?.id || 'unknown')
        setUserProperties(createUserProperties(userData))
      }
    },
  })

  if (inProgress === 'login') {
    return <PageLoading />
  } else {
    return (
      <>
        <UnauthenticatedTemplate>
          <Login
            onLogin={() =>
              msalInstance
                .loginRedirect(loginRequest)
                .catch((e) => console.log(e))
            }
          />
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      </>
    )
  }
}

export default Authentication
