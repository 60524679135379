import { sortBy } from 'lodash'

import { addLabelsToData } from '@/config/dataFormatters/utils/conviva'
import { MetricSettingsI } from '@/config/types'
import isNumber from '@/utils/isNumber'

const fallback: {
  value: any
  id: string
  image?: string
  color?: string
}[] = []

const convivaAggregationToPie = ({
  settings,
  config,
  data,
  chartColors,
}: {
  settings: MetricSettingsI
  config: ConfigI
  data: ConvivaAggregationResponseI
  chartColors: string[]
}) => {
  const { dataKey } = settings
  const splitBy = config['split-by']

  if (data?.data) {
    const formattedData = Object.keys(data?.data)
      .filter(
        (split) => split !== 'total' && isNumber(data?.data[split][0][dataKey]),
      )
      .map((split) => {
        const splitData = data?.data[split]
        return {
          id: split,
          value: splitData[0][dataKey],
        }
      })

    const dataWithLabels = addLabelsToData({
      data: formattedData,
      settings,
      splitBy,
      chartColors,
    })

    const sortedDataWithLabels = sortBy(dataWithLabels, ({ id }) => id)

    return sortedDataWithLabels
  } else {
    return fallback
  }
}

export default convivaAggregationToPie
