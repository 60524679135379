import { maxBy } from 'lodash'

import { MetricSettingsI } from '@/config/types'

const fallback: { [key: string]: number | string } = {}

const convivaTimeseriesPeak = ({
  settings,
  data,
}: {
  settings: MetricSettingsI
  data: ConvivaTimeseriesResponseI
}) => {
  const { dataKey, annotationFlags } = settings

  if (data?.data) {
    // Hack to fix fact API always returns total object
    if (
      Object.keys(data?.data)?.length === 1 &&
      data?.data?.total?.length === 0
    )
      return fallback

    if (annotationFlags?.peak?.logic === 'total') {
      if (data?.data?.total) {
        const min = maxBy(data.data.total, dataKey) || fallback
        min.y = min[dataKey]
        min.x = min.timestamp
        min.onSeries = 'total'
        return min
      } else {
        return fallback
      }
    } else {
      const maxBySplit = Object.keys(data?.data).map((split) => {
        const peak = maxBy(data.data[split], dataKey) || fallback
        peak.y = peak[dataKey]
        peak.x = peak.timestamp
        peak.onSeries = split
        return peak
      })

      if (maxBySplit.length > 0) {
        return maxBy(maxBySplit, 'y') || fallback
      } else {
        return fallback
      }
    }
  } else {
    return fallback
  }
}

export default convivaTimeseriesPeak
