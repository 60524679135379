import { meanBy } from 'lodash'

import { MetricSettingsI } from '@/config/types'

const fallback = 0

const convivaTimeseriesAverage = ({
  settings,
  data,
}: {
  settings: MetricSettingsI
  data: ConvivaTimeseriesResponseI
}) => {
  const { dataKey } = settings

  if (data?.data?.total) {
    return meanBy(data.data.total, dataKey) || fallback
  } else {
    return fallback
  }
}

export default convivaTimeseriesAverage
