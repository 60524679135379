import './styles/index.css'

import { getAnalytics, isSupported } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { ErrorBoundary } from 'react-error-boundary'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter as Router } from 'react-router-dom'

import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser'

import App from './App'
import { msalConfig } from './authConfig'
import ErrorFallback from './components/ErrorFallback'
import appSettings from './config/appSettings'
import ThemeProvider from './hocs/ThemeProvider'
import reportWebVitals from './reportWebVitals'
import { initFaro } from './utils/faro'

const { analytics } = appSettings

export const msalInstance = new PublicClientApplication(msalConfig)

// FIREBASE ANALYTICS
// Export analytics instance, it's used in firebaseAnalytics.ts file to define Firebase functions
export let analyticsInstance: ReturnType<typeof getAnalytics> | null = null

if (
  // When working locally, go to appSettings -> analytics and change enabled
  analytics?.enabled
) {
  const { firebaseConfig = {} } = analytics
  const app = initializeApp(firebaseConfig)

  // Initialize Analytics only if supported (avoids errors in unsupported environments)
  isSupported().then((supported) => {
    if (supported) {
      analyticsInstance = getAnalytics(app)
      console.log('Firebase Analytics initialized:', analyticsInstance)
    } else {
      console.log('Firebase Analytics is not supported in this environment.')
    }
  })
}

export const queryClient = new QueryClient(
  // This includes global options for React Query. Feel free to remove this altogether or edit the options
  {
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnWindowFocus: true,
        refetchInterval: 1000 * 60 * 1, // ms * seconds * number of minutes
        staleTime: 1000 * 60 * 0.75, // ms * seconds * number of minutes,
        keepPreviousData: true,
      },
    },
  },
)
const container = document.getElementById('root')
const root = createRoot(container!)

initFaro()

msalInstance.initialize().then(() => {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts()
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0])
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult
      const account = payload.account
      msalInstance.setActiveAccount(account)
    }
  })
  root.render(
    <React.StrictMode>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={() => {
          // Logging here
        }}
      >
        <QueryClientProvider client={queryClient}>
          <ThemeProvider>
            <Router>
              <App pca={msalInstance} />
            </Router>
          </ThemeProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </React.StrictMode>,
  )
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
