import clsx from 'clsx'
import { CurrentRefinementsProvided } from 'react-instantsearch-core'
import { connectCurrentRefinements } from 'react-instantsearch-dom'

import { Button } from '@mui/material'

interface ClearRefinementsPropsI extends CurrentRefinementsProvided {
  className: string
}

const ClearRefinements = ({
  items,
  refine,
  className,
}: ClearRefinementsPropsI) => {
  if (items.length === 0) return null
  return (
    <div className={clsx(className)}>
      <Button
        variant='outlined'
        fullWidth
        onClick={() => refine(items)}
        color='error'
      >
        Clear Filters
      </Button>
    </div>
  )
}

const CustomClearRefinements =
  connectCurrentRefinements<ClearRefinementsPropsI>(ClearRefinements)

export default CustomClearRefinements
