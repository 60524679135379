import { startCase } from 'lodash'

import { MetricSettingsI } from '@/config/types'
import getFormOption from '@/utils/getFormOption'

export function addLabelsToData({
  data = [],
  splitBy,
  settings,
  chartColors,
}: {
  data: {
    isTotal?: boolean
    value: any
    timestamps?: number[]
    id: string
  }[]
  splitBy?: string | null
  settings?: MetricSettingsI
  chartColors?: string[]
}) {
  if (!settings || !splitBy) return data

  return data?.map(({ id, value, ...rest }) => {
    const { label, image, color } = getFormOption({
      value: id,
      formGroup: splitBy,
      settings,
      chartColors,
    })

    return { id: label || startCase(id), value, image, color, ...rest }
  })
}
