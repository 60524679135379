import { minBy } from 'lodash'

import { MetricSettingsI } from '@/config/types'

const fallback: { [key: string]: number | string } = {}

const convivaTimeseriesMin = ({
  settings,
  data,
}: {
  settings: MetricSettingsI
  data: ConvivaTimeseriesResponseI
}) => {
  const { dataKey, annotationFlags } = settings
  if (data?.data) {
    // Hack to fix fact API always returns total object
    if (
      Object.keys(data?.data)?.length === 1 &&
      data?.data?.total?.length === 0
    ) {
      return fallback
    }

    if (annotationFlags?.min?.logic === 'total') {
      if (data?.data?.total) {
        const min = minBy(data.data.total, dataKey) || fallback
        min.y = min[dataKey]
        min.x = min.timestamp
        min.onSeries = 'total'
        return min
      } else {
        return fallback
      }
    } else {
      const minBySplit = Object.keys(data?.data).map((split) => {
        // if (Object.keys(data?.data).includes("total") && split !== "total")
        //   return null;
        const min = minBy(data.data[split], dataKey) || fallback
        min.y = min[dataKey]
        min.x = min.timestamp
        min.onSeries = split
        return min
      })
      // .filter((m) => !!m);

      if (minBySplit.length > 0) {
        return minBy(minBySplit, 'y') || fallback
      } else {
        return fallback
      }
    }
  } else {
    return fallback
  }
}

export default convivaTimeseriesMin
